import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './Footer.scss'

const Footer = ({ mobile = false }) => (
	<footer className='footer'>
		{!mobile && (
			<div className='footer__top-bar'>
				<Link to='/mentions-legales' className='footer__link'>
					Mentions Légales
				</Link>
				{/* <Link to="/contact" className="footer__link">Contact</Link> */}
			</div>
		)}
		<div className='footer__bottom-bar'>
			{mobile && (
				<>
					<Link to='/contact' className='footer__link'>
						Contact
					</Link>
					<div className='footer__social'>
						<a
							href='https://www.instagram.com/pauline_gorlier_soins_naturels'
							target='_blank'
							rel='noopener noreferrer'
						>
							<svg
								height='511pt'
								viewBox='0 0 511 511.9'
								width='511pt'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									fill='#ffffff'
									d='M510.95 150.5c-1.2-27.2-5.598-45.898-11.9-62.102-6.5-17.199-16.5-32.597-29.6-45.398-12.802-13-28.302-23.102-45.302-29.5-16.296-6.3-34.898-10.7-62.097-11.898C334.648.3 325.949 0 256.449 0s-78.199.3-105.5 1.5c-27.199 1.2-45.898 5.602-62.097 11.898-17.204 6.5-32.602 16.5-45.403 29.602-13 12.8-23.097 28.3-29.5 45.3-6.3 16.302-10.699 34.9-11.898 62.098C.75 177.801.449 186.5.449 256s.301 78.2 1.5 105.5c1.2 27.2 5.602 45.898 11.903 62.102 6.5 17.199 16.597 32.597 29.597 45.398 12.801 13 28.301 23.102 45.301 29.5 16.3 6.3 34.898 10.7 62.102 11.898 27.296 1.204 36 1.5 105.5 1.5s78.199-.296 105.5-1.5c27.199-1.199 45.898-5.597 62.097-11.898a130.934 130.934 0 0074.903-74.898c6.296-16.301 10.699-34.903 11.898-62.102 1.2-27.3 1.5-36 1.5-105.5s-.102-78.2-1.3-105.5zm-46.098 209c-1.102 25-5.301 38.5-8.801 47.5-8.602 22.3-26.301 40-48.602 48.602-9 3.5-22.597 7.699-47.5 8.796-27 1.204-35.097 1.5-103.398 1.5s-76.5-.296-103.403-1.5c-25-1.097-38.5-5.296-47.5-8.796C94.551 451.5 84.45 445 76.25 436.5c-8.5-8.3-15-18.3-19.102-29.398-3.5-9-7.699-22.602-8.796-47.5-1.204-27-1.5-35.102-1.5-103.403s.296-76.5 1.5-103.398c1.097-25 5.296-38.5 8.796-47.5C61.25 94.199 67.75 84.1 76.352 75.898c8.296-8.5 18.296-15 29.398-19.097 9-3.5 22.602-7.7 47.5-8.801 27-1.2 35.102-1.5 103.398-1.5 68.403 0 76.5.3 103.403 1.5 25 1.102 38.5 5.3 47.5 8.8 11.097 4.098 21.199 10.598 29.398 19.098 8.5 8.301 15 18.301 19.102 29.403 3.5 9 7.699 22.597 8.8 47.5 1.2 27 1.5 35.097 1.5 103.398s-.3 76.301-1.5 103.301zm0 0'
								/>
								<path
									fill='#fff'
									d='M256.45 124.5c-72.598 0-131.5 58.898-131.5 131.5s58.902 131.5 131.5 131.5c72.6 0 131.5-58.898 131.5-131.5s-58.9-131.5-131.5-131.5zm0 216.8c-47.098 0-85.302-38.198-85.302-85.3s38.204-85.3 85.301-85.3c47.102 0 85.301 38.198 85.301 85.3s-38.2 85.3-85.3 85.3zm0 0M423.852 119.3c0 16.954-13.747 30.7-30.704 30.7-16.953 0-30.699-13.746-30.699-30.7 0-16.956 13.746-30.698 30.7-30.698 16.956 0 30.703 13.742 30.703 30.699zm0 0'
								/>
							</svg>
						</a>
						<a
							href='https://www.linkedin.com/in/pauline-gorlier-6a13361b6/'
							target='_blank'
							rel='noopener noreferrer'
						>
							<svg
								height='682pt'
								viewBox='0 0 682 682'
								width='682pt'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									fill='white'
									d='m77.613281-.667969c-46.929687 0-77.613281 30.816407-77.613281 71.320313 0 39.609375 29.769531 71.304687 75.8125 71.304687h.890625c47.847656 0 77.625-31.695312 77.625-71.304687-.894531-40.503906-29.777344-71.320313-76.714844-71.320313zm0 0'
								/>
								<path
									fill='white'
									d='m8.109375 198.3125h137.195313v412.757812h-137.195313zm0 0'
								/>
								<path
									fill='white'
									d='m482.054688 188.625c-74.011719 0-123.640626 69.546875-123.640626 69.546875v-59.859375h-137.199218v412.757812h137.191406v-230.5c0-12.339843.894531-24.660156 4.519531-33.484374 9.917969-24.640626 32.488281-50.167969 70.390625-50.167969 49.644532 0 69.5 37.851562 69.5 93.339843v220.8125h137.183594v-236.667968c0-126.78125-67.6875-185.777344-157.945312-185.777344zm0 0'
								/>
							</svg>
						</a>
						<a
							href='https://www.facebook.com/Pauline-Gorlier-Praticienne-en-soins-naturels-et-%C3%A9nerg%C3%A9tiques-Lille-101885001591490'
							target='_blank'
							rel='noopener noreferrer'
						>
							<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
								<switch>
									<g>
										<path
											d='M288 176v-64c0-17.7 14.3-32 32-32h32V0h-64c-53 0-96 43-96 96v80h-64v80h64v256h96V256h64l32-80h-96z'
											fill='#fff'
										/>
									</g>
								</switch>
							</svg>
						</a>
					</div>
					<Link to='/mentions-legales' className='footer__link'>
						Mentions Légales
					</Link>
				</>
			)}
			<p>
				Site réalisé par{' '}
				<a href='https://mgdg.fr' target='_blank' rel='noopener noreferrer'>
					MGDG
				</a>{' '}
				et{' '}
				<a
					href='https://www.linkedin.com/in/kevin-lanvin-0208281b5/'
					target='_blank'
					rel='noopener noreferrer'
				>
					Kévin Lanvin
				</a>
			</p>
		</div>
	</footer>
)

Footer.propTypes = {
	mobile: PropTypes.bool,
}

export default Footer
